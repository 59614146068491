const CartIcon = ({ color = "#676560" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00017 0C6.19292 0 4.72786 1.45204 4.72786 3.24323V3.45952H4.06124C3.26063 3.45952 3.09077 3.46032 3.09077 3.46032L2.11429 14.4313C2.08819 14.8047 2 15.9996 2 15.9996C2 15.9996 2.94303 16 3.38753 16H12.6131C13.0576 16 14 15.9996 14 15.9996C14 15.9996 13.9124 14.8047 13.8864 14.4313L12.9077 3.45968C12.9077 3.45968 12.74 3.45952 11.9394 3.45952H11.2725V3.24323C11.2725 1.45204 9.80739 0 8.00017 0ZM9.96356 4.75681V5.83781H11.2725V4.75681H11.8727C11.8864 4.78146 11.9026 4.82358 11.9068 4.884L12.5806 14.521C12.5864 14.6046 12.5659 14.6676 12.5464 14.7027H3.45425C3.43476 14.6676 3.41424 14.6046 3.42009 14.521L4.09379 4.88399C4.09802 4.82354 4.11424 4.78146 4.12795 4.75681H4.72786V5.83781H6.03678V4.75681H9.96356ZM9.96356 3.45952V3.24323C9.96356 2.16852 9.0845 1.29729 8.00017 1.29729C6.91582 1.29729 6.03678 2.16852 6.03678 3.24323V3.45952H9.96356Z"
        fill={color}
      />
    </svg>
  );
};

export default CartIcon;
