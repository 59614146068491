const Clipboard = ({ color = "#9E9B99" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_31211_150820)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.84998 -0.150024H12.15V1.34998H14.65V15.65H1.84998V1.34998H3.84998V-0.150024ZM3.84998 2.64998H3.14998V14.35H13.35V2.64998H12.15V4.64998H3.84998V2.64998ZM5.14998 1.14998V3.34998H10.85V1.14998H5.14998ZM11.928 8.48915L7.47035 12.3896L5.54036 10.4596L6.45959 9.54036L7.5296 10.6104L11.0719 7.5108L11.928 8.48915Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_31211_150820">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Clipboard;
