import { useEffect, useRef, useState } from "react";

export const useInputClearButton = () => {
  const [isClearButtonVisible, setIsClearButtonVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  let timeoutId: number = 0;

  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, []);

  const showClearButton = () => {
    window.clearTimeout(timeoutId);
    setIsClearButtonVisible(true);
  };

  const removeClearButton = () => {
    timeoutId = window.setTimeout(() => setIsClearButtonVisible(false), 300);
  };

  const restoreFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
      window.clearTimeout(timeoutId);
    }
  };

  return {
    isClearButtonVisible,
    showClearButton,
    removeClearButton,
    inputRef,
    restoreFocus,
  };
};
