const Route = ({ color = "#9E9B99" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_31211_150829)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3333 3.94667C12.6264 3.94667 12.0533 3.37359 12.0533 2.66667C12.0533 1.95974 12.6264 1.38667 13.3333 1.38667C14.0403 1.38667 14.6133 1.95974 14.6133 2.66667C14.6133 3.37359 14.0403 3.94667 13.3333 3.94667ZM13.3333 5.33333C11.8606 5.33333 10.6667 4.13943 10.6667 2.66667C10.6667 1.19391 11.8606 0 13.3333 0C14.8061 0 16 1.19391 16 2.66667C16 4.13943 14.8061 5.33333 13.3333 5.33333ZM3.74594 3.6059H8.557V2.13333H0L12.2541 13.4608H8V14.9333H16L3.74594 3.6059ZM1.38667 13.3333C1.38667 14.0403 1.95974 14.6133 2.66667 14.6133C3.37359 14.6133 3.94667 14.0403 3.94667 13.3333C3.94667 12.6264 3.37359 12.0533 2.66667 12.0533C1.95974 12.0533 1.38667 12.6264 1.38667 13.3333ZM0 13.3333C0 14.8061 1.19391 16 2.66667 16C4.13943 16 5.33333 14.8061 5.33333 13.3333C5.33333 11.8606 4.13943 10.6667 2.66667 10.6667C1.19391 10.6667 0 11.8606 0 13.3333Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_31211_150829">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Route;
