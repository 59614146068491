import classNames from "classnames";

import styles from "./OnePlusOneLabel.module.scss";

interface OnePlusOneLabelProps {
  className?: string;
}

const OnePlusOneLabel = ({ className = "" }: OnePlusOneLabelProps) => {
  return <div className={classNames(styles.root, className)}>1+1=3</div>;
};

export default OnePlusOneLabel;
