import Head from "next/head";
import { ReactNode } from "react";

import Favicons from "./Favicons";

export interface LayoutBaseProps {
  title?: string;
  children: ReactNode;
  className?: string;
}

const LayoutBase = ({ title, children, className }: LayoutBaseProps) => {
  return (
    <div className={className}>
      <Head>
        <title>{title ? `LOOK ONLINE. ${title}` : "LOOK ONLINE"}</title>
        <Favicons />
      </Head>
      {children}
    </div>
  );
};

export default LayoutBase;
