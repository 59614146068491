interface ExpandIconProps {
  color?: string;
  className?: string;
  size?: number;
}

const ExpandIcon = ({
  color = "#676560",
  className = "",
  size = 10,
}: ExpandIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 2.85175L1.392 2L8 9.17915L14.608 2L15.5 2.85175L8 11L0.5 2.85175Z"
        fill={color}
      />
    </svg>
  );
};

export default ExpandIcon;
