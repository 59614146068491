import classNames from "classnames";

import { canOldPriceShow } from "../../../helpers/canOldPriceShow";
import { ButtonWhiteBorder } from "../ButtonWhiteBorder";
import { OnePlusOneLabel } from "../OnePlusOneLabel";
import styles from "./AddToCartButton.module.scss";

interface AddToCartButtonProps {
  onClick(): void;
  price: string | number;
  oldPrice?: string | number;
  className?: string;
  crossed?: boolean;
  onePlusOne: boolean;
}

const AddToCartButton = ({
  onClick,
  price,
  oldPrice,
  className,
  crossed,
  onePlusOne,
}: AddToCartButtonProps) => {
  const fomattedPrice = Number(price).toLocaleString("ru-RU") + "р.";
  const fomattedOldPrice =
    oldPrice && Number(oldPrice).toLocaleString("ru-RU") + "р.";

  return (
    <ButtonWhiteBorder
      onClick={onClick}
      className={classNames(styles.root, className)}
    >
      <>
        {onePlusOne && <OnePlusOneLabel className={styles.onePlusOne} />}
        <img src="/static/img/icons/cart-icon.svg" alt="add to cart button" />
        <div className={styles.priceContainer}>
          {canOldPriceShow({
            crossed: crossed,
            price: price,
            old_price: oldPrice,
          }) && <p className={styles.oldPrice}>{fomattedOldPrice}</p>}
          <p>{fomattedPrice}</p>
        </div>
      </>
    </ButtonWhiteBorder>
  );
};

export default AddToCartButton;
