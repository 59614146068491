interface SearchIconProps {
  color?: string;
  className?: string;
}

const SearchIcon = ({ color = "#EEEDEA", className = "" }: SearchIconProps) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.70856 1.5082C3.38876 1.5082 1.5082 3.38876 1.5082 5.70856C1.5082 8.02836 3.38876 9.90893 5.70856 9.90893C6.83074 9.90893 7.85014 9.46886 8.60361 8.75189C8.62447 8.72427 8.64749 8.69777 8.67267 8.67259C8.69783 8.64743 8.72433 8.62442 8.75194 8.60356C9.46888 7.8501 9.90893 6.83072 9.90893 5.70856C9.90893 3.38876 8.02836 1.5082 5.70856 1.5082ZM10.2432 9.17671C10.9796 8.21536 11.4171 7.01306 11.4171 5.70856C11.4171 2.55581 8.86131 0 5.70856 0C2.55581 0 0 2.55581 0 5.70856C0 8.86131 2.55581 11.4171 5.70856 11.4171C7.01308 11.4171 8.2154 10.9795 9.17675 10.2432L10.7127 11.7791C11.0072 12.0736 11.4846 12.0736 11.7791 11.7791C12.0736 11.4846 12.0736 11.0072 11.7791 10.7127L10.2432 9.17671Z"
        fill={color}
      />
    </svg>
  );
};

export default SearchIcon;
