import { YANDEX_METRIC, YM_JS } from "@/config";

export const ymReachGoalHelper = (goalName: string, payload?: object) => {
  if (!YANDEX_METRIC || !YM_JS) return;

  try {
    // eslint-disable-next-line no-undef, @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ym(YANDEX_METRIC, "reachGoal", goalName, payload && payload);
  } catch (error) {
    //
  }
};
