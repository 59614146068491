const Cross = ({ color = "#EEEDEA", size = "16" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.357429 0.357429C0.834001 -0.119143 1.60668 -0.119143 2.08325 0.357429L8 6.27418L13.9167 0.357429C14.3933 -0.119143 15.166 -0.119143 15.6426 0.357429C16.1191 0.834001 16.1191 1.60668 15.6426 2.08325L9.72582 8L15.6426 13.9167C16.1191 14.3933 16.1191 15.166 15.6426 15.6426C15.166 16.1191 14.3933 16.1191 13.9167 15.6426L8 9.72582L2.08325 15.6426C1.60668 16.1191 0.834001 16.1191 0.357429 15.6426C-0.119143 15.166 -0.119143 14.3933 0.357429 13.9167L6.27418 8L0.357429 2.08325C-0.119143 1.60668 -0.119143 0.834001 0.357429 0.357429Z"
        fill={color}
      />
    </svg>
  );
};

export default Cross;
