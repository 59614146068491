import { ymReachGoalHelper } from "./ymReachGoalHelper";

export const ymUseFilterGoal = () => {
  const alreadyUseFilter = window.sessionStorage.getItem("isFilterAlreadyUsed");
  if (alreadyUseFilter) {
    return ymReachGoalHelper("filter-used");
  }
  ymReachGoalHelper("filter-used-for-the-first-time");
  window.sessionStorage.setItem("isFilterAlreadyUsed", "1");
};
