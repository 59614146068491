import classNames from "classnames";

import styles from "./ButtonBlured.module.scss";

interface ButtonBluredProps {
  onClick?(): void;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
}

const ButtonBlured = ({
  onClick,
  disabled = false,
  className,
  children,
}: ButtonBluredProps) => {
  return (
    <button
      className={classNames(styles.root, className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default ButtonBlured;
