const ArrowLeft = ({ color = "#676560" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.48848 8.65002L6.87886 13.0404L5.95962 13.9596L0 8.00002L5.95962 2.04041L6.87886 2.95964L2.48848 7.35002H16V8.65002H2.48848Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowLeft;
