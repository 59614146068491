import React from "react";

const Favicons = () => {
  return (
    <>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/static/favicon/apple-touch-icon.png"
        key="icon_1"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/static/favicon/favicon-32x32.png"
        key="icon_2"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/static/favicon/favicon-16x16.png"
        key="icon_3"
      />
      <link
        rel="manifest"
        href="/static/favicon/site.webmanifest"
        key="manifest"
      />
      <link
        rel="mask-icon"
        href="/static/favicon/safari-pinned-tab.svg"
        color="#5bbad5"
        key="icon_4"
      />
      <link
        rel="shortcut icon"
        href="/static/favicon/favicon.ico"
        key="icon_5"
      />
      <meta
        name="msapplication-TileColor"
        content="#ffc40d"
        key="title_color"
      />
      <meta
        name="msapplication-config"
        content="/static/favicon/browserconfig.xml"
        key="msapp_config"
      />
    </>
  );
};

export default Favicons;
