const Cube = ({ color = "#9E9B99" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_31211_150826)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99992 0L15.5 4V12L8.00008 16L0.5 12L0.5001 4L7.99992 0ZM8.01482 1.20338L2.4079 4.24649L7.9999 7.30504L13.5919 4.24648L8.01482 1.20338ZM14.2252 5.28988L14.216 11.367L14.2157 11.3683C14.2118 11.3714 14.216 11.367 14.216 11.367L8.63715 14.4634L8.63713 8.34628L14.2252 5.28988ZM7.36267 8.34628L1.77465 5.28991L1.78417 11.3683L7.36269 14.4634L7.36267 8.34628ZM14.216 11.367L14.2157 11.3683L14.216 11.367Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_31211_150826">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Cube;
