const Check = ({ color = "#929292", size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_31095_194382)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 0.771639L5.5627 16L0 9.38683L1.10688 8.50594L5.44523 13.6636L14.81 0L16 0.771639Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_31095_194382">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Check;
