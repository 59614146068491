import classNames from "classnames";

import { ArrowLeft } from "@/components/icons";

import styles from "./BackButton.module.scss";

interface BackButtonProps {
  onClick(): void;
  className?: string;
  iconColor?: string;
}

const BackButton = ({ onClick, className, iconColor }: BackButtonProps) => {
  return (
    <button className={classNames(styles.root, className)} onClick={onClick}>
      <ArrowLeft color={iconColor} />
    </button>
  );
};

export default BackButton;
