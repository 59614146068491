import classNames from "classnames";
import { ReactNode } from "react";

import styles from "./ButtonWhiteBorder.module.scss";

interface ButtonWhiteBorderProps {
  onClick(): void;
  className?: string;
  children: ReactNode | string | number;
  disabled?: boolean;
}

const ButtonWhiteBorder = ({
  onClick,
  className,
  children,
  disabled = false,
}: ButtonWhiteBorderProps) => {
  return (
    <button
      className={classNames(styles.root, className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default ButtonWhiteBorder;
