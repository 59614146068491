import classNames from "classnames";

import { ymReachGoalHelper } from "@/components/YandexMetrica";

import styles from "./MuteButton.module.scss";

interface MuteButtonProps {
  muted: boolean;
  toggle(): void;
  className?: string;
}

const MuteButton = ({ muted, toggle, className = "" }: MuteButtonProps) => {
  return (
    <button
      className={classNames(styles.root, className)}
      onClick={() => {
        ymReachGoalHelper("sound-toggle");
        toggle();
      }}
    >
      <div className={styles.backGround}>
        <img
          className={styles.icon}
          src={
            muted
              ? "/static/img/icons/sound-off.svg"
              : "/static/img/icons/sound-on.svg"
          }
          alt="mute button"
        />
      </div>
    </button>
  );
};

export default MuteButton;
