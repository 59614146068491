import classNames from "classnames";

import styles from "./CardHeader.module.scss";

interface CardHeaderProps {
  leftSlot?: JSX.Element;
  centerSlot?: JSX.Element;
  rightSlot?: JSX.Element;
  className?: string;
  leftSlotClassName?: string;
  centerSlotClassName?: string;
  rightSlotClassName?: string;
}

const CardHeader = ({
  leftSlot,
  centerSlot,
  rightSlot,
  className,
  leftSlotClassName = "",
  centerSlotClassName = "",
  rightSlotClassName = "",
}: CardHeaderProps) => {
  return (
    <header className={classNames(styles.root, className)}>
      {leftSlot && <div className={leftSlotClassName}>{leftSlot}</div>}
      {centerSlot && <div className={centerSlotClassName}>{centerSlot}</div>}
      {rightSlot && <div className={rightSlotClassName}>{rightSlot}</div>}
    </header>
  );
};

export default CardHeader;
